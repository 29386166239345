import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';

const SoloGuidesJinwoo: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Best skills and runes for Jinwoo</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_skill.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Best skills and runes for Jinwoo</h1>
          <h2>
            Find out the best skills and runes you should use on Jinwoo in Solo
            Leveling: Arise.
          </h2>
          <p>
            Last updated: <strong>19/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Skill setups and best runes" />
        <StaticImage
          src="../../../images/solo/generic/beginner_skills2.webp"
          alt="Guide"
        />
        <p>
          When it comes to skills, they all fall within specific niches so we’ll
          do our best to summarize the different groups and what they bring to
          the table. It’s important to note that we’ll try to not mention
          numerical values in the evaluation because they are subject to change
          with the skill rune rarity, meaning the number of additional casts,
          seconds and values of buff/debuffs could change a lot.
        </p>
        <SectionHeader title="Break-focused Skills" />
        <p>
          <strong>
            Crushing Blow, Multistrike, Death Dance, Armor Break and now Dagger
            Rush
          </strong>{' '}
          - these skills all offer different variations of{' '}
          <strong>break damage to the enemy</strong>. It’s good to have at least
          one of these equipped all the time for convenient access to breaking,
          on top of all the different combinations with runes.
        </p>
        <p>
          What to equip comes down to the fight and your personal taste - in
          general, they all have a wide elemental coverage, and some of them add
          special buff/debuffs or interact with other skills. Keep in mind that
          some bosses do not have a break gauge, so you could remove your break
          skills to focus more on damage or utility.
        </p>
        <h5>Crushing Blow</h5>
        <p>
          <strong>Crushing Blow</strong> series focuses on upgrades inflicting
          knock-down or airborne effects to the enemy.
        </p>
        <ul>
          <li>
            <strong className="Wind">Wind</strong> inflicts knock down on the
            enemy, buffs your attack when the skill hits and applies a shield
            when using the skill,
          </li>
          <li>
            <strong className="Light">Light</strong> sends the enemy airborne
            and inflicts Light on the enemy, dealing damage equal to the attack
            for 30 seconds,
          </li>
          <li>
            <strong className="Dark">Dark</strong> sends the enemy airborne and
            gives a shield for 20 seconds, if hit while the shield is active you
            will unleash a powerful counterattack that stuns and deal heavy
            break damage,
          </li>
          <li>
            <strong className="Fire">Fire</strong> knocks down the enemy and
            gives a charge bar gain, on top of reducing the cooldown of your
            ultimate by 10 seconds.
          </li>
        </ul>
        <h5>Multistrike</h5>
        <p>
          <strong>Multistrike</strong> series deals a good chunk of damage while
          providing great buffs, with a Water, fire, dark and light variant.
        </p>
        <p>All the versions have their good niche:</p>
        <ul>
          <li>
            <strong className="Water">Water</strong> increases crushing blow
            damage and adds a light break at the end of the skill for more
            breaking power,
          </li>
          <li>
            <strong className="Fire">Fire</strong> increases the damage to
            bosses and elite monsters,
          </li>
          <li>
            <strong className="Dark">Dark</strong> adds invincibility during the
            skill, on top of giving a stacking damage buff for the skill and
            resets cd for dagger toss,
          </li>
          <li>
            <strong className="Light">Light</strong> gives a crit rate buff and
            resets the skill cd every 20 seconds.
          </li>
        </ul>
        <h5>Death Dance</h5>
        <p>
          <strong>Death Dance</strong> deals high break damage on top of
          offering some interesting skill interactions.
        </p>
        <ul>
          <li>
            <strong className="Light">Light</strong> version knocks down the
            enemy, increases crit hit damage and resets cooldown for commander’s
            touch. We’ll talk more about commander’s touch later but this is by
            far one of the strongest options you can take. It’s also important
            that since this crit damage comes from a skill it will still be in
            effect,
          </li>
          <li>
            <strong className="Wind">Wind</strong> can be used up to 3 times and
            increases your power gauge when hit,
          </li>
          <li>
            <strong className="Dark">Dark</strong> sends enemies airborne and
            increases damage dealt to bosses and enemies,
          </li>
          <li>
            <strong className="Fire">Fire</strong> inflicts knockdown and adds
            20% damage when hitting an elemental weakness.
          </li>
        </ul>
        <p>
          <strong>
            If you need to break a boss weakness you should start by
            prioritizing the correct elemental break regardless of the skill
          </strong>
          , especially when starting out. As you progress and get access to more
          hunter supports and weapons you should tweak the skills on the fight
          and your personal preference.
        </p>
        <p>
          Out of the three skills, <strong>Multistrike and Death Dance</strong>{' '}
          feel the most impactful, with light death dance being a strong
          universal choice due to the crit damage buff and the cd reset on
          commander’s touch.
        </p>
        <h5>Armor Break</h5>
        <p>
          The first of the break oriented skills to bring almighty break damage,
          <strong>Armor Break</strong> also doubles as a powerful damage buff
          option either for SJW or the entire team.
        </p>
        <ul>
          <li>
            <strong className="Light">Light</strong> version deals damage with
            multipliers split between ATK and DEF, while making SJW invincible
            during the animation and boosting his DEF for 30 seconds with up to
            4 stacks,
          </li>
          <li>
            <strong className="Fire">Fire</strong> is all about damage, with a
            very high multiplier and a huge Crit damage boost for SJW, lasting
            only 5 seconds,
          </li>
          <li>
            <strong className="Wind">Wind</strong> is the perfect mix of high
            break and utility, with a potent Damage increased debuff on enemies
            hit. Ideal for any scoring content where SJW and Hunters play at the
            same time,
          </li>
          <li>
            <strong className="Water">Water</strong> has the benefit of applying
            Freeze over a large area, with not much else to it.
          </li>
        </ul>
        <h5>Dagger Rush</h5>
        <p>
          Extremely strong addition to SJW’s toolkit,{' '}
          <strong>Dagger Rush</strong> is the second Break Skill to feature
          ‘Almighty’ Break damage, and just like the better Break Skills it also
          brings more than just Break like buffs, debuffs or very high damage.
        </p>
        <ul>
          <li>
            <strong className="Wind">Wind</strong> is the only version of the
            Skill with heavy Break, and in exchange it applies a stackable
            debuff on enemies’ hit that increases the Crit damage they receive,
            making it a good pick when you already have powerful skills on the
            side (like a highly invested Dkd),
          </li>
          <li>
            <strong className="Dark">Dark</strong> version focuses on damage,
            with a huge damage increase if the enemy doesn’t have a Break Gauge
            (like Sonic Stream - Double Down -). This skill, combined with -
            Magnifying Slash - makes for an amazing Burst Setup in Dark Builds,
            offering amazing Break and incredible damage with Moonshadow and
            Plum weapons,
          </li>
          <li>
            <strong className="Fire">Fire</strong> brings a massive personal
            Shield to SJW that only lasts a few seconds, but is perfect to
            Trigger Shield based buffs or survive a nuke, on top of having a
            very wide AoE with high base damage,
          </li>
          <li>
            <strong className="Water">Water</strong> version deals small damage
            around SJW repeatedly for a few seconds and applies the Frostbite
            DoT to them, on top of increasing the Damage dealt to enemies within
            Melee Range of SJW for the duration of the skill.
          </li>
        </ul>
        <SectionHeader title="Damage-focused Skills" />
        <p>
          While some of these skills can also offer utility and buffs, at the
          end of the day they mostly bring damage to the tale. These skills are
          great when focusing on killing the enemy fast, especially if you do
          not need to focus on a break gauge.
        </p>
        <h5>Double Slash</h5>
        <ul>
          <li>
            Full Moon Wheel inflicts [Airborne] on the enemies and boosts damage
            to non-bosses,
          </li>
          <li>
            Multiply has a chance to reset the skill CD and increases the damage
            of Mutilate for 10 seconds,
          </li>
          <li>
            Flame Slash changes the element to fire and sends enemies Airborne,
            on top of adding a Crit rate buff,
          </li>
          <li>
            Whirlwind Rush sends enemies airborne and also stuns them. When the
            skill hits, it adds one dash charge. This one can have its usages,
            especially against enemies that require a lot of repositioning.
          </li>
        </ul>
        <h5>Dagger Toss</h5>
        <ul>
          <li>
            Detonation adds the <strong className="Fire">Fire</strong> element
            to the skill and burns the enemy for 10 seconds,
          </li>
          <li>
            Electric Shock changes the element to{' '}
            <strong className="Light">Light</strong> and inflicts a debuff every
            time it hits that deals damage based on atk,
          </li>
          <li>Ascensions increases the damage to broken targets,</li>
          <li>
            Bombardment knocks enemy down and inflicts more damage to knocked
            down enemies.
          </li>
        </ul>
        <h5>Vertical Arts</h5>
        <ul>
          <li>
            Sequent explosions change the element to{' '}
            <strong className="Water">Water</strong>; it starts the combo by
            knocking enemies down and then sends them airborne. It also
            increases the damage to broken target,
          </li>
          <li>
            Crosshairs changes the element to{' '}
            <strong className="Fire">Fire</strong>, sends enemies airborne and
            increases the damage to bosses and elite bosses,
          </li>
          <li>
            Reap changes the element to <strong className="Dark">Dark</strong>,
            knocking down enemies and adding a shield to Jinwoo. You can
            counterattack using this skill before being hit, stunning the enemy,
          </li>
          <li>
            Gale changes the element to <strong className="Wind">Wind</strong>{' '}
            and knocks enemies down, increasing the damage dealt to non bosses
            enemies.
          </li>
        </ul>
        <h5>Cutting Rush</h5>
        <ul>
          <li>
            Annihilation sends enemies airborne and increases damage to broken
            enemies,
          </li>
          <li>
            Effulgence knocks enemies down, increasing Crit Damage for 12
            seconds. It will also reset its own cooldown when used,
          </li>
          <li>
            Sunder changes the element to <strong className="Dark">Dark</strong>{' '}
            and sends enemies airborne, while also granting the [Invincible]
            status while the skill is in effect and giving extra crit rate for
            12 seconds,
          </li>
          <li>
            Escalate gives a chance to give a 100% guaranteed critical strike on
            the next hit when attacking an enemy with a back attack.
          </li>
        </ul>
        <h5>Vital Strike</h5>
        <ul>
          <li>
            Decimate adds an ATKk% buff for 12 seconds after using the skill,
          </li>
          <li>
            Internal wound sends the enemy airborne and adds a Def Down debuff
            for 12 seconds.{' '}
            <strong>
              This is very strong, as you can use it when Shadow Step is on
              cooldown to have a longer uptime of this important debuff on the
              enemy,
            </strong>
          </li>
          <li>
            Hone in increases damage to bosses and elite bosses by a significant
            amount when using the skill,
          </li>
          <li>
            On point changes the element of the skill to{' '}
            <strong className="Water">Water</strong> and adds a Shield for 12
            seconds.
          </li>
        </ul>
        <h5>Mutilate</h5>
        <ul>
          <li>
            Cold storm changes the element of the skill to{' '}
            <strong className="Water">Water</strong> and inflicts Frostbite
            (damage over time) and Freeze for 2 seconds,
          </li>
          <li>
            Phantom adds more damage based on Jinwoo’s HP and also resets the
            skill CD when hittingm
          </li>
          <li>Strike decreases the enemy Defense and Attack for 12 seconds,</li>
          <li>
            Stroke of Lightning changes the element to{' '}
            <strong className="Dark">Dark</strong>. It’s a charging skill that
            can knock down enemies and also has a change to ignore the enemy
            defense when attacking.
          </li>
        </ul>
        <h5>Sonic Stream</h5>
        <p>
          Newest addition to the game, Sonic Stream offers both damage and
          utility options, and was in fact so strong in terms of damage that it
          prompted Netmarble to buff the other damage skills to bring them up to
          its power.
        </p>
        <ul>
          <li>
            <strong className="Light">Light</strong> is a pure damage skill,
            inflicting correct damage and boosting its own damage when the
            target doesn’t have an active Break Gauge (Raid Cut was the old name
            of Sonic Stream),
          </li>
          <li>
            <strong className="Fire">Fire</strong> fully charges your Core
            Attack upon landing the final hit, and also buffs the damage of all
            your Core Attacks for the next few seconds. Excellent in Core Attack
            setups to improve your Burst,
          </li>
          <li>
            <strong className="Wind">Wind</strong> builds its damage up
            depending on the number of hits before the final blow, while also
            granting your SJW increased ATK per each hit that landed. Great
            generic tool to increase your Attack in a Wind setup,
          </li>
          <li>
            <strong className="Dark">Dark</strong> may have low base
            multipliers, but its damage can be exponentially increased by timing
            it properly during your Burst window, especially if you’re using
            Plum Swords at A3.
          </li>
        </ul>
        <p>
          Out of these skills, the most notable and universal ones are{' '}
          <strong>Sonic Stream, Cutting Rush, Vital Strike and Mutilate</strong>
          . Cutting Rush offers extra utility, Vital Strike can offer powerful
          buffs/debuffs and Mutilate has a strong universal debuff and damaging
          abilities. Sonic Stream can bring a lot of damage and self buffs to
          the table, so long as the skill fits well in your setup.
        </p>
        <SectionHeader title="Commander’s Touch" />
        <p>
          This skill is just so awesome that it gets its specific paragraph.
          While it has no element attached to it it’s one of the most
          devastating utility skills in the game:
        </p>
        <ul>
          <li>
            <strong>Black Hole:</strong> this version of the skill gathers all
            enemies in one spot, dealing increased damage to non bosses.
            Phenomenal when it comes to quickly clear maps with a lot of
            enemies, especially in the later story chapters.
          </li>
          <li>
            <strong>Finisher:</strong> Knocks down the enemy and inflicts a
            powerful defense down for 12 seconds
          </li>
          <li>
            <strong>Liberation:</strong> knocks down an enemy and can be used up
            to 2 times. Every time it hits, your attack increases for 12 seconds
            and it can stack two times.
          </li>
          <li>
            <strong>Absorption:</strong> sends enemies airborne, then knocks
            them down at the end of the combo. When it hits, you recover 3% of
            your mana points and recover 3% of the power gauge, for a maximum of
            3 times.
          </li>
        </ul>
        <p>
          All effects are great based on the scenario so you can’t go wrong with
          it. As mentioned, the light version of Death Dance will reset the
          cooldown for Commander’s touch, so you can quickly stack the atk buff,
          keep permanent uptime on the defense break and much more.
        </p>
        <SectionHeader title="QTE Skills" />
        <p>
          QTE skills are used when dodging an attack, they have no runes so it’s
          much more straightforward: just use <strong>Shadow Step</strong>, the
          time stop and huge Defense Down debuff are just invaluable.
        </p>
        <SectionHeader title="Ultimate Skills" />
        <p>
          For ultimate skills, you should always use{' '}
          <strong>Truth: Mutilate</strong>.
        </p>
        <SectionHeader title="Closing Remarks" />
        <p>
          To close this section off, you can generally plan your skills around
          your weapons: if you plan to run an offensive combo like Plum and
          Scythe/moonlight dagger, getting some extra break power or cc is going
          to help you dish out more damage, coupled up with crit rate/damage
          buffs.
        </p>
        <p>
          If you already plan to bring some strong cc options like Book of
          Themis, then you can run more offensive skills to compensate for the
          weapon’s damage outside of the cc window. When progressing through the
          story or playing gates, you should probably include Commander’s Touch
          given it’s strong grouping power and overall flexibility.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesJinwoo;

export const Head: React.FC = () => (
  <Seo
    title="Best skills and runes for Jinwoo | Solo Leveling: Arise | Prydwen Institute"
    description="Find out the best skills and runes you should use on Jinwoo in Solo Leveling: Arise."
    game="solo"
  />
);
